<template>
  <div class="p-3">
    <b-form
      @submit.stop.prevent="onSubmit"
      @reset.prevent="resetForm"
      autocomplete="off"
    >
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group
            label="Aktif Diller"
            description="Aktif Olacak Dilleri Belirleyiniz."
          >
            <v-select
              v-model="form.diller"
              :options="sabit_diller"
              :reduce="(sabit) => sabit.lang"
              label="title"
              multiple
              :clearable="false"
              @input="handlerDilChange"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            label="Varsayılan"
            description="Varsayılan Dili Belirleyiniz."
          >
            <v-select
              v-model="form.varsayilan"
              :options="form.diller"
              :reduce="(dil) => dil.lang"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col cols="12" md="6">
          <div class="d-flex">
            <b-button
              squared
              block
              type="submit"
              size="lg"
              variant="primary"
              class="mr-2"
            >
              <i class="fad fa-save pr-2"></i>
              {{ form.k_no == null ? "EKLE" : "GÜNCELLE" }}
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { defineComponent, ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import { langs } from "@/libs/languages";
import store from "@/store";

export default defineComponent({
  components: {
    vSelect,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.form = ref({
      _id: null,
      diller: [],
      varsayilan: null,
    });
    expo.sabit_diller = ref(langs);

    const handlerConfig = async () => {
      context.emit("show", true);
      await store.dispatch("dilAyarlariGetir").then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit("show", false);
    };

    handlerConfig();

    expo.handlerDilChange = (event) => {
      let selectLang = [];
      event.forEach((element) => {
        selectLang.push(langs.find((x) => x.lang == element));
      });
      expo.form.value.diller = selectLang;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("dilAyarlariEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(
              expo.form.value.k_no == null
                ? "Ekleme Başarılı."
                : "Güncelleme Başarılı",
              { position: "bottom-left" }
            );
            context.emit("show", false);
          }
        })
        .catch((err) => {
          context.emit("show", false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
